export const UNIT_EDIT_CLICKED = 'UnitEditClicked';
export const UNIT_NAME_CHANGED = 'UnitNameEdited';
export const UNIT_PRICE_CHANGED = 'UnitPriceEdited';
export const UNIT_SEASONAL_PRICING_ACTIVATED = 'SeasonalPricingActivated';
export const UNIT_SEASONAL_PRICING_DEACTIVATED = 'SeasonalPricingDeactivated';
export const UNIT_OCCUPANCY_ADDED = 'OccupancyAdded';
export const UNIT_OCCUPANCY_DELETED = 'OccupancyDeleted';
export const UNIT_OCCUPANCY_EDITED = 'OccupancyEdited';
export const UNIT_OCCUPANCIES_ICAL_EDITED = 'OccupancyICalEdited';
export const UNIT_OCCUPANCIES_CALENDAR_TAB_CLICKED =
  'OccupancyCalendarTabClicked';
export const UNIT_OCCUPANCIES_ICAL_TAB_CLICKED = 'OccupancyICalTabClicked';
export const UNIT_LISTING_VIEWED = 'UnitListingViewed';
export const UNIT_CALL_US_CLICKED = 'UnitCallUsClicked';
export const UNIT_DEPOSIT_EDITED = 'UnitDepositEdited';
export const TENANT_REQUIREMENTS_CLICK_EVENT = 'TenantRequirementsClicked';
export const UNIT_MIN_STAY_EDITED = 'UnitMinStayEdited';
export const UNIT_MAX_STAY_EDITED = 'UnitMaxStayEdited';
export const UNIT_BILLS_EDITED = 'UnitBillsEdited';
export const UNIT_RULES_EDITED = 'UnitRulesEdited';
export const UNIT_PROPERTY_DETAILS_EDITED = 'UnitPropertyDetailsEdited';
export const UNITS_UNPUBLISH = 'UnitsUnpublish';
export const NAVIGATE_EDIT_LISTING_SECTION = 'EditListingSectionVisited';
export const CONTACT_FORM_LINK_VISITED = 'contactFormLinkVisited';
export const UNIT_STATS_BOOKING_LEADS_CLICKED = 'BookingLeadsInfoClicked';
export const UNIT_STATS_VIEWS_LOADED = 'UnitViewsLoaded';
export const UNIT_STATS_PROPERTY_VIEWS_CLICKED = 'ViewersInfoClicked';
export const UNIT_STATS_INTEREST_LOADED = 'UnitInterestLoaded';
export const UNIT_INTEREST_VIEW_PROFILE_CLICKED =
  'UnitInterestViewProfileClicked';
export const UNIT_SEND_INTEREST_CLICKED = 'UnitSendInterestClicked';
export const UNIT_SEND_INTEREST_BOOKING_MODAL_CLOSED =
  'UnitSendInterestBookingModalClosed';
export const UNIT_SEND_INTEREST_CHECK_OCCUPANCIES_MODAL_BUTTON_CLICKED =
  'UnitSendInteresCheckOccupanciesModalButtonClicked';
export const UNIT_SEND_INTEREST_ERROR_MODAL_CLOSED =
  'UnitSendInterestErrorModalClosed';
export const UNIT_SEND_INTEREST_SUCCESS_MODAL_CLOSED =
  'UnitSendInterestSuccessModalClosed';
export const UNIT_SEND_INTEREST_MODAL_CLOSED = 'UnitSendInterestModalClosed';

/* Property List Segment Events */
export const PROPERTY_LIST_SEARCH_USED = 'PropertySearchUsed';
export const PROPERTY_LIST_SEARCH_ERROR = 'PropertySearchError';
export const PROPERTY_CALENDAR_REFRESHED = 'PropertyCalendarRefreshed';
export const PROPERTY_LANDLORD_DESCRIPTION_UPDATED =
  'PropertyLandlordDescriptionUpdated';
export const PROPERTY_TYPE_CHANGE_SUBMITTED = 'PropertyTypeChangeSubmitted';

export const PROPERTY_PHOTO_UPLOADER_CLICKED = 'PropertyPhotoUploaderClicked';
export const PROPERTY_PHOTO_ADDED = 'PropertyPhotoAdded';
export const PROPERTY_PHOTO_DELETED = 'PropertyPhotoDeleted';
export const PROPERTY_PHOTO_SET_AS_COVER = 'PropertyPhotoSetAsCover';
export const PROPERTY_PHOTO_ACTIONS_DROPDOWN_CLICKED =
  'PropertyPhotoActionsDropdownClicked';
export const PROPERTY_PHOTO_ORDER_CHANGED = 'PropertyPhotoOrderChanged';
export const PROPERTY_PHOTO_SAVED = 'PropertyPhotoSaved';
export const PROPERTY_PHOTO_CATEGORY_MODIFIED = 'PropertyPhotoCategoryModified';
export const PROPERTY_PHOTO_TIPS_SHOWN = 'PropertyPhotoTipsShown';
export const PROPERTY_PHOTO_TIPS_CLOSED = 'PropertyPhotoTipsClosed';

export const DISCOVER_PLANS_CLICKED = 'DiscoverPlansClicked';
export const DISCOVER_PLANS_MODAL_SHOWN = 'DiscoverPlansModalShown';
export const PLANS_CONTACT_CLICK = 'PlansContactClick';
export const PLANS_UPGRADE_CLICK = 'PlansUpgradeClick';
export const PLANS_UPGRADE_CONFIRMATION_CLICK = 'PlansUpgradeConfirmationClick';
export const DEFAULT_LANDLORD_PLAN_UPDATED = 'DefaultLandlordPlanUpdated';

export const ONBOARDING_TOOLTIP_ACKNOWLEDGED = 'OnboardingTooltipAcknowledged';

export const PAGE_EXIT = 'PageExit';

export const PENDING_BOOKINGS_BANNER_SHOWN = 'PendingBookingsBannerShown';
export const PENDING_BOOKINGS_BANNER_CLOSED = 'PendingBookingsBannerClosed';
export const PENDING_BOOKINGS_BANNER_BUTTON_CLICKED =
  'PendingBookingsBannerButtonClicked';

export const WHATSAPP_DESKTOP_BUTTON_CLICKED = 'onClickWhatsappButtonDesktop';
export const DIRECT_CONTACT_MOBILE_BUTTON_CLICKED =
  'onClickWhatsappButtonMobile';
export const DIRECT_CONTACT_DESKTOP_BUTTON_CLICKED =
  'onClickDirectSupportDesktop';
export const WHATSAPP_MOBILE_BUTTON_CLICKED = 'onClickDirectSupportMobile';
export const READ_MORE_COMMISSION_INFO_CLICKED =
  'ReadMoreCommissionInfoClicked';
export const PRICE_SUGGESTION_TOOLTIP_IS_SHOWN =
  'PriceSuggestionTooltipIsShown';

/* Import properties */
export const PROPERTY_SOURCE_SUBMITTED = 'PropertySourceSubmitted';
export const PROPERTY_SOURCE_SELECTED = 'PropertySourceSelected';
export const IMPORT_LISTING_BACK_BUTTON_CLICKED =
  'ImportListingBackButtonClicked';
export const IMPORT_LISITNG_ADD_ANOTHER_CLICKED =
  'ImportListingAddAnotherClicked';
export const IMPORT_LISTING_SUBMITTED = 'ImportListingSubmitted';
export const IMPORT_LISTING_SUBMITTED_ERROR = 'ImportListingSubmittedError';
export const IMPORT_LISTING_BACK_TO_LISTING_CLICKED =
  'ImportListingBackToListingClicked';
export const HELP_LINK_CLICK_SELF_SERVICE = 'HelpLinkClickSelfService';
export const SAVE_AND_EXIT_LINK_CLICK_SELF_SERVICE =
  'SaveAndExitLinkClickSelfService';
export const EDIT_AVAILABILITY_LINK_CLICKED = 'EditAvailabilityLinkClicked';
export const BOOKING_TOO_EARLY_WAS_CHANGED = 'bookingTooEarlyChanged';
export const MIN_MAX_STAY_WAS_CHANGED = 'minMaxStayChanged';
export const PLANS_BUTTON_CLICKED = 'plansButtonClicked';
export const RENT_COLLECTION_TOGGLE_CLICKED = 'rentCollectionToggleClicked';
export const RENT_COLLECTION_TOOLTIP_HOVER = 'rentCollectionTooltipHover';
export const UPDATE_ICAL_SYNCHRONIZATION = 'updateICalSynchronization';
export const UPDATE_ICAL_SYNCHRONIZATION_ERROR =
  'updateICalSynchronizationError';
export const UPDATE_ICAL_SYNCHRONIZATION_SUCCESS =
  'updateICalSynchronizationSuccess';
export const COPY_ICAL_LINK = 'copyICalLink';

export const APPLY_NO_DEPOSIT_SELF_SERVICE_SUGGESTION =
  'applyNoDepositSuggestionSelfService';
export const MONTHLY_PRICE_ADD_LISTING_SUGGESTION_APPLIED =
  'monthlyPriceAddListingSuggestionApplied';
export const NO_SECURITY_DEPOSIT_ADD_LISTING_SUGGESTION_APPLIED =
  'noSecurityDepositAddListingSuggestionApplied';
export const ALL_BILLS_INCLUDED_ADD_LISTING_APPLIED =
  'allBillsIncludedAddListingSuggestionApplied';

export default {
  UNIT_EDIT_CLICKED,
  UNIT_NAME_CHANGED,
  UNIT_PRICE_CHANGED,
  UNIT_SEASONAL_PRICING_ACTIVATED,
  UNIT_SEASONAL_PRICING_DEACTIVATED,
  UNIT_OCCUPANCY_ADDED,
  UNIT_OCCUPANCY_DELETED,
  UNIT_OCCUPANCY_EDITED,
  UNIT_OCCUPANCIES_CALENDAR_TAB_CLICKED,
  UNIT_OCCUPANCIES_ICAL_TAB_CLICKED,
  UNIT_LISTING_VIEWED,
  UNIT_CALL_US_CLICKED,
  UNIT_DEPOSIT_EDITED,
  TENANT_REQUIREMENTS_CLICK_EVENT,
  UNIT_BILLS_EDITED,
  PROPERTY_LIST_SEARCH_USED,
  PROPERTY_LIST_SEARCH_ERROR,
  PROPERTY_CALENDAR_REFRESHED,
  UNIT_STATS_VIEWS_LOADED,
  UNIT_STATS_PROPERTY_VIEWS_CLICKED,
  UNIT_STATS_INTEREST_LOADED,
  UNIT_STATS_BOOKING_LEADS_CLICKED,
  ONBOARDING_TOOLTIP_ACKNOWLEDGED,
  PAGE_EXIT,
  UNIT_INTEREST_VIEW_PROFILE_CLICKED,
  UNIT_SEND_INTEREST_CLICKED,
  UNIT_SEND_INTEREST_BOOKING_MODAL_CLOSED,
  UNIT_SEND_INTEREST_CHECK_OCCUPANCIES_MODAL_BUTTON_CLICKED,
  UNIT_SEND_INTEREST_ERROR_MODAL_CLOSED,
  UNIT_SEND_INTEREST_SUCCESS_MODAL_CLOSED,
  UNIT_SEND_INTEREST_MODAL_CLOSED,
  DISCOVER_PLANS_CLICKED,
  READ_MORE_COMMISSION_INFO_CLICKED,
  PROPERTY_SOURCE_SUBMITTED,
  PROPERTY_SOURCE_SELECTED,
  IMPORT_LISTING_BACK_BUTTON_CLICKED,
  IMPORT_LISITNG_ADD_ANOTHER_CLICKED,
  IMPORT_LISTING_SUBMITTED,
  IMPORT_LISTING_SUBMITTED_ERROR,
  IMPORT_LISTING_BACK_TO_LISTING_CLICKED,
  EDIT_AVAILABILITY_LINK_CLICKED,
  BOOKING_TOO_EARLY_WAS_CHANGED,
  MIN_MAX_STAY_WAS_CHANGED,
  RENT_COLLECTION_TOGGLE_CLICKED,
  RENT_COLLECTION_TOOLTIP_HOVER
};
