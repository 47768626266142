import Tracking from '@spotahome/soyuz-tracking';
import PropTypes from 'prop-types';
import { Popover, useMediaQuery, Slide, Grow, IconButton } from '@mui/material';
import { useTheme } from '@mui/styles';

import { Typography } from '@spotahome/ui-library/base';

import { trans, useSoyuzUser } from '@spotahome/soyuz/client';

import VpnKeyIcon from '@mui/icons-material/VpnKey';

import CloseIcon from '@mui/icons-material/Close';

import useStylesNormalizedFont from '../../../styles';

import NotificationItem from '../NotificationItem';

import StarsIcon from '../../../../../../../icons/Stars';
import AlertIcon from '../../../../../../../icons/Alert';

import { NOTIFICATION_CLICKED } from '../../../../../../../utils/segment/events';

import { NotificationModalHeader, NotificationModalBody } from './styles';

const NotificationModal = ({
  open,
  handleClose,
  anchorEl = null,
  notifications = [],
  onClickNotifications
}) => {
  const muiTheme = useTheme();
  const isMobile = !useMediaQuery(muiTheme.breakpoints.up('sm'));
  const normalizedFontclass = useStylesNormalizedFont();
  const user = useSoyuzUser();

  const handleClick = notificationLink => {
    Tracking.segment.trackEvent(NOTIFICATION_CLICKED, user, {
      user_id: user.id,
      notificationLink
    });

    onClickNotifications();
    setTimeout(() => {
      window.location = notificationLink;
    }, 1000);
  };

  const iconNames = {
    Key: <VpnKeyIcon />,
    Stars: <StarsIcon />,
    Alert: <AlertIcon />
  };

  return (
    <Popover
      className={`
        ${normalizedFontclass.fontNormalizer}
      `}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      marginThreshold={0}
      open={open}
      onClose={handleClose}
      TransitionComponent={isMobile ? Slide : Grow}
      TransitionProps={{
        direction: isMobile ? 'left' : 'up'
      }}
      slotProps={{
        paper: {
          sx: {
            maxWidth: 'none',
            maxHeight: 'none',
            height: {
              xs: '100vh',
              sm: 'auto'
            },
            width: {
              xs: '100vw',
              sm: '343px'
            },
            boxShadow: {
              sm: '0px 2px 8px 0px rgba(0, 0, 0, 0.18)'
            }
          }
        },
        root: {
          slotProps: {
            backdrop: {
              invisible: false,
              sx: {
                background:
                  'linear-gradient(45deg, transparent, rgba(255, 255, 255, 0.7))',
                inset: isMobile ? '0 0 0 0' : '65px 0 0 0',
                backdropFilter: 'blur(3px)'
              },
              'data-cy': 'notification-modal-backdrop'
            }
          }
        }
      }}
      sx={{
        inset: isMobile ? '0 0 0 0' : '65px 0 0 0'
      }}
    >
      <NotificationModalHeader data-testid="notification-modal">
        <Typography.HeadingS>
          {trans('sahc.notification-menu.modal.title')}
        </Typography.HeadingS>
        <IconButton
          onClick={handleClose}
          data-testid="notification-modal-close"
          size="large"
          sx={{
            color: 'var(--sah-interaction-dark)'
          }}
        >
          <CloseIcon fontSize="large" />
        </IconButton>
      </NotificationModalHeader>

      <NotificationModalBody>
        {notifications.map(notification => (
          <NotificationItem
            color={notification.category.toLowerCase()}
            notificationIcon={iconNames[notification.iconName]}
            onClick={() => handleClick(notification.link)}
            key={notification.link}
            text={trans(
              notification.translationKey,
              ...notification.translationData.map(data => ({
                [data.key]: data.value
              }))
            )}
          />
        ))}
      </NotificationModalBody>
    </Popover>
  );
};

NotificationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  anchorEl: PropTypes.object,
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      iconName: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
      category: PropTypes.string.isRequired,
      translationData: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.string.isRequired,
          value: PropTypes.string.isRequired
        })
      ).isRequired,
      translationKey: PropTypes.string.isRequired
    })
  ),
  onClickNotifications: PropTypes.func.isRequired
};

export default NotificationModal;
