import Tracking from '@spotahome/soyuz-tracking';

export const trackLandlordJoinCta = () => {
  Tracking.ga.sendEvent('landlord_landing', 'clickBtn', 'mainBtn');
};

export const trackLandlordAccessCta = () => {
  Tracking.ga.sendEvent('landlord_landing', 'clickBtn', 'loginBtn');
};

export const trackLandlordStepsToPublishClicked = step => {
  Tracking.ga.sendEvent('landlord_landing', 'clickStep', step);
};
export const trackLandlordStepsToPublishCta = () => {
  Tracking.ga.sendEvent('landlord_landing', 'clickBtn', 'stepsToPublish');
};

export const trackLandlordLandingFAQs = faq => {
  Tracking.ga.sendEvent('landlord_landing', 'clickFaq', faq);
};

export const trackLandlordLandingSectionViewed = section => {
  Tracking.ga.sendEvent('landlord_landing', 'view', section);
};
