import moment from 'moment';

// TYPES

/**
 * Represents the inclusivity options, from|to edges,
 * for date range comparing operations, like isBetweenX functions
 *
 * @typedef {Object} Inclusivity
 * @property {boolean} from
 * @property {boolean} to
 */

// CONSTANTS
const monthsPerYear = 12;
const monthKeys = [
  'jan',
  'feb',
  'mar',
  'apr',
  'may',
  'jun',
  'jul',
  'aug',
  'sep',
  'oct',
  'nov',
  'dec'
];

export const defaultDateFormat = 'YYYY-MM-DD';
export const displayDateFormat = 'DD-MM-YYYY';

/**
 * Inclusivity constants
 *
 * @type {{
 *  excludeBoth: Inclusivity,
 *  includeBoth: Inclusivity,
 *  includeFrom: Inclusivity,
 *  includeTo: Inclusivity
 * }}
 */
export const inclusivity = {
  excludeBoth: {
    from: false,
    to: false
  },
  includeBoth: {
    from: true,
    to: true
  },
  includeFrom: {
    from: true,
    to: false
  },
  includeTo: {
    from: false,
    to: true
  }
};

/**
 * Default inclusivity
 *
 * @type {Inclusivity}
 */
export const defaultInclusivity = inclusivity.excludeBoth;

/**
 * Set default locale to be used on dates format
 *
 * @param {string} locale
 */
export function setDefaultLocale(locale) {
  moment.locale(locale);
}

// HELPERS

/**
 * Parses Inclusivity to moment format
 *
 * @param {Inclusivity} incl
 * @returns {"()"|"[)"|"(]"|"[]"}
 */
function toMomentInclusivity(incl) {
  return `${incl.from ? '[' : '('}${incl.to ? ']' : ')'}`;
}

// DATE FUNCTIONS

/**
 * Difference of months between 2 dates
 *
 * @param from
 * @param to
 * @returns {number}
 */
export function monthDiff(from, to) {
  const monthsDate1 = from.getFullYear() * monthsPerYear + from.getMonth();
  const monthsDate2 = to.getFullYear() * monthsPerYear + to.getMonth();

  return monthsDate1 - monthsDate2;
}

/**
 * Difference of days between 2 dates
 *
 * @param from
 * @param to
 * @returns {number}
 */
export function dayDiff(from, to) {
  return moment(from).diff(to, 'days');
}

/**
 * Difference of years between 2 dates
 *
 * @param from
 * @param to
 * @returns {number}
 */
export function yearDiff(from, to) {
  return moment(from).diff(to, 'years');
}

/** Difference of hours between 2 dates
 *
 * @param from
 * @param to
 * @returns {number}
 */

export function hoursDiff(from, to) {
  return moment(from).diff(to, 'hours');
}

/**
 * Checks if a date is between from|to dates
 *
 * @param {Date} date
 * @param {Date} from
 * @param {Date} to
 * @param {Inclusivity} incl
 * @returns {boolean}
 */
export function isBetweenDays(date, from, to, incl = defaultInclusivity) {
  return moment(date).isBetween(from, to, 'day', toMomentInclusivity(incl));
}

/**
 * Checks if a date is between from|to (both inclusive) dates
 *
 * @param date
 * @param from
 * @param to
 * @returns {boolean}
 */
export function isBetweenDaysInclusive(date, from, to) {
  return isBetweenDays(date, from, to, inclusivity.includeBoth);
}

/**
 * Checks if a date is between the months of from|to dates
 *
 * @param {Date} date
 * @param {Date} from
 * @param {Date} to
 * @param {Inclusivity} incl
 * @returns {boolean}
 */
export function isBetweenMonths(date, from, to, incl = defaultInclusivity) {
  return moment(date).isBetween(from, to, 'month', toMomentInclusivity(incl));
}

/**
 * Checks if a date is between the months of from|to (both inclusive) dates
 *
 * @param date
 * @param from
 * @param to
 * @returns {boolean}
 */
export function isBetweenMonthsInclusive(date, from, to) {
  return isBetweenMonths(date, from, to, inclusivity.includeBoth);
}

/**
 * Returns the date of the first day of the month
 *
 * @param date
 * @returns {Date}
 */
export function startOfMonth(date) {
  return moment(date).startOf('month').toDate();
}

/**
 * Returns the date of the last day of the month
 *
 * @param date
 * @returns {Date}
 */
export function endOfMonth(date) {
  return moment(date).endOf('month').toDate();
}

/**
 * Returns a date with the increment in terms of months
 *
 * @param {Date} date
 * @param {number} increment - a positive|negative number of months
 * @returns {Date}
 */
export function addMonths(date, increment) {
  return moment(date).add(increment, 'month').toDate();
}

/**
 * Returns a date with the decrement in terms of months
 *
 * @param {Date} date
 * @param {number} decrement - a positive|negative number of months
 * @returns {Date}
 */
export function substractMonths(date, decrement) {
  return moment(date).subtract(decrement, 'month').toDate();
}

/**
 * Returns a date with the increment in terms of days
 *
 * @param {Date} date
 * @param {number} increment - a positive|negative number of days
 * @returns {Date}
 */
export function addDays(date, increment) {
  return moment(date).add(increment, 'day').toDate();
}

/**
 * Checks if two dates are in the same day
 *
 * @param date1
 * @param date2
 * @returns {boolean}
 */
export function sameDay(date1, date2) {
  return (
    date1.getDate() === date2.getDate() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getFullYear() === date2.getFullYear()
  );
}

/**
 * Returns the date of the first day of month of the given year, month
 *
 * @param {number} year
 * @param {number} month
 * @returns {Date}
 */
export function dateFromYearMonth(year, month) {
  return new Date(year, month, 1);
}

/**
 * Returns the key representation of the month of the date (for example: "2018-01-01" -> 'jan')
 *
 * @param date
 * @returns {string}
 */
export function monthKey(date) {
  return monthKeys[date.getMonth()];
}

/**
 * Returns the day of a date (for example: "2019-04-01" -> 1)
 *
 * @param date
 * @returns {string}
 */
export function getDay(date) {
  return moment(date).getDate();
}

/**
 * Returns a localized representation for a given month (for example "Jan" -> "Ene" if our locale is ES)
 *
 * @param {string} monthName
 * @returns {string}
 */
export function localizedMonthName(monthName) {
  return moment().day(1).month(monthKeys.indexOf(monthName)).format('MMM');
}

/**
 * Returns a localized formatted string of the date
 * Warning: this function is tightly coupled to moment js format
 *
 * @param date
 * @param formatString - the output string format, see docs:
 *  https://momentjs.com/docs/#/displaying/format/
 *  https://devhints.io/datetime#momentjs-format
 * @returns {string}
 */
export function format(date, formatString = defaultDateFormat) {
  return moment(date).format(formatString);
}

/**
 * Returns a date formatted into string with the general display format
 *
 * @param date
 * @returns {string}
 */

export function dateToString(date) {
  return moment(date).format(displayDateFormat);
}
/**
 * Returns a localized string with the elapsed time from the given date (for example: "10 seconds ago")
 *
 * @param since
 * @returns {string}
 */
export function formatRelativeDate(since) {
  return moment().to(since);
}

/**
 *  Checks if a date happened before (or in the same day) a reference date (default today) in the timeunit established (default days)
 *
 * @param {initialDate} date
 * @param {referenceDate} date
 * @returns {boolean}
 */

export function isDaySameOrBefore(initialDate, referenceDate = new Date()) {
  return moment(initialDate).isSameOrBefore(referenceDate, 'days');
}
