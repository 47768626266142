import gql from 'graphql-tag';

export const PROPERTIES_PER_PAGE = 10;

export const GET_LANDLORD_PROPERTIES_LIST = gql`
  query PropertiesList($input: PropertiesInput) {
    properties(input: $input) {
      properties {
        id
        address
        description
        isDraft
        totalRentableUnits
        isShared
        lastPhotosession {
          id
          date
          stage
          location {
            addressLine1
            addressLine2
          }
          homeChecker {
            email
            firstName
            lastName
            phone
            profilePicture
          }
        }
        location {
          cityId
        }
        units {
          id
          url
          landlordReference
          lastAvailabilityUpdate
          availability {
            isAvailable
            availableFrom
          }
          price {
            type
            currency
            amount
            isFixed
          }
          photo {
            thumbnailUrl
          }
          isProperty
          isActive
          isVerified
        }
      }
      totalProperties
    }
  }
`;

export const GET_LANDLORD_PROPERTIES_LIST_TOTAL_AMOUNT = gql`
  query PropertiesListTotalAmount($input: PropertiesInput) {
    properties(input: $input) {
      totalProperties
    }
  }
`;

export const GET_LANDLORD_LISTINGS = gql`
  query getLandlordListings(
    $page: Int!
    $itemsPerPage: Int!
    $sortBy: String
    $sortDirection: String
    $searchText: String
  ) {
    getLandlordListings(
      page: $page
      itemsPerPage: $itemsPerPage
      sortBy: $sortBy
      sortDirection: $sortDirection
      searchText: $searchText
    ) {
      listings {
        listingId
        propertyId
        bedroomNumber
        ownerId
        principalPhoto
        address
        lastAvailabilitiesDate
        nextAvailableDate
        isAvailable
        minPrice
        maxPrice
        currency
        currencySymbol
        landlordReference
        isVerified
        isDeleted
        isDisabled
        optimalPriceEstimation
        priceOptimizationStatus
        interests
        numberUnits
        totalScoreLabel
        totalScorePoints
        propertyType
        state
        url
      }
      page
      itemsPerPage
      totalListings
      totalOverallListings
    }
  }
`;

export const GET_COUNT_LANDLORD_LISTINGS = gql`
  query getCountLandlordListings {
    getLandlordListings(page: 1, itemsPerPage: 1) {
      totalListings
    }
  }
`;

export const GET_ALL_LANDLORD_LISTINGS_IDS = gql`
  query getAllLandlordListingsIds {
    getAllLandlordListingsIds
  }
`;
