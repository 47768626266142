import { Button } from '@spotahome/ui-library';
import { trans, getPagePath } from '@spotahome/soyuz/client';
import Typography from '@spotahome/landlord-panel-ui-library/src/components/Typography';

import { trackLandlordJoinCta } from '../../utils/track';

import PlusPicture from './PlusPicture';
import useStyles from './styles';

const PlusSection = () => {
  const { content: classes } = useStyles();
  const handleOnClickCta = () => {
    trackLandlordJoinCta();
  };

  return (
    <main className={classes.main}>
      <PlusPicture pictureId="landing-plus-section" />
      <PlusPicture pictureId="landing-plus-section-sm" isMobile />
      <section className={classes.wrapper}>
        <div className={classes.ctaContent}>
          <Typography variant="DisplayL">
            {trans('landlord.landing.plus-section.title')}
          </Typography>

          <Typography variant="ParagraphL">
            {trans('landlord.landing.plus-section.subtitle')}
          </Typography>

          <Button
            data-test="landlord-plus-cta"
            className={classes.cta}
            size="big"
            color="primary"
            href={getPagePath('landlord.signup')}
            onClick={handleOnClickCta}
          >
            {trans('landlord.landing.plus-section.cta')}
          </Button>
        </div>
      </section>
    </main>
  );
};

export default PlusSection;
